import React, { Component } from 'react'
import logo from './assets/logonew1.svg'
import ReactGA from 'react-ga'
import './App.scss'

const track = (category, action) => ReactGA.event({ category, action })

class App extends Component {
  render() {
    return (
      <div
        className="app"
        onClick={e => {
          const category = e.target.innerText
          if (category.length < 50) {
            track(category, 'click')
          }
        }}
      >
        <div className="app__content">
          <div className="app__header">
            <div className="app__logo">
              <img alt="logo" src={logo} />
            </div>
          </div>
          <div className="app__spacing" />
          <div className="app__top">
            <div className="app__main-text-wrapper">
              <div className="app__main-text">
                Lock provides timelocking solutions for your crypto assets.
              </div>
            </div>
          </div>

          <div className="app__bottom">
            <div className="app__main-text-wrapper">
              <div className="app__point">
                <span className="text--bold">HOLD </span>- Timelock your ETH and ERC-20 tokens (or #HODL)
              </div>
              <div className="app__point">
                <span className="text--bold">EARN </span>- Vest tokens for team members and investors
              </div>
              <div className="app__point">
                <span className="text--bold">VEST </span>- Incentivize liquidity providers to stay on your exchange
              </div>
              <div className="app__point">
                <div className="app__point">
                  <span className="text--bold">DISTRIBUTE </span>- Send airdrops to anyone who has locked your token
                </div>
              </div>
            </div>
          </div>

          <div className="app__bottom">
            <button className="btn__primary" onClick={() => window.open('https://app.lock.finance/', '_blank')}>
              Use App
            </button>
            <button className="btn__primary" onClick={() => window.open('https://docs.lock.finance/', '_blank')}>
              Read Docs
            </button>
          </div>
        </div>
        <div className="app__actions">
          <a href="https://github.com/lockfinance" target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
          <a href="https://twitter.com/lockprotocol" target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
          <a href="https://medium.com/lockprotocol" target="_blank" rel="noopener noreferrer">
            Medium
          </a>
          <a href="https://t.me/lockprotocol" target="_blank" rel="noopener noreferrer">
            Telegram
          </a>
          <a href="mailto:hello@lock.finance">Email</a>
        </div>
      </div>
    )
  }
}

export default App
